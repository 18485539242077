<script>
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import { required, email } from 'vuelidate/lib/validators'
export default {
  name: 'ModalInviteMember',
  components: { InputField, SelectField },
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedRole: 'participant',
      availableRoles: [
        'participant',
        'manager',
        'admin'
      ],
      approvalFlowSteps: [],
      dependents: [],
      formData: {
        email: '',
        approvalFlow: null,
        dependent: null
      }
    }
  },
  created () {
    this.getApprovalFlow()
    this.getDependents()
  },
  validations: {
    formData: {
      email: {
        required,
        email,
        isEnliztEmail: (val) => {
          return val !== '' && !['enlizt.com', 'enlizt.com.br', 'enlizt.br'].includes(val.split('@')[1])
        }
      }
    }
  },
  methods: {
    close (close) {
      this.formData.email = null
      this.formData.approvalFlow = null
      this.formData.dependent = null
      this.selectedRole = 'participant'
      this.$v.$reset()
      if (close) this.$emit('close')
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const payload = {
        email: this.formData.email.toLowerCase(),
        role: this.selectedRole,
        dependent: { _id: this.formData.dependent },
        approvalFlowSteps: { steps: this.formData.approvalFlow }
      }
      this.$store.dispatch('attemptSendInvite', payload)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('modal.invite.member:feedback.success')
          })
          this.close(false)
          this.$emit('close')
        })
        .catch(err => {
          if (err.response.data.error === 'userExists') {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('registration.form:already_registered')
            })
          } else if ((err.response.data.msg === 'Invalid email')) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('global:validation.invalid.enlizt.email')
            })
          } else if ((err.response.data.error === 'userDisabled')) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('registration.form:user_disabled')
            })
          } else {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('settings.admincenter.users.labels:add.error')
            })
          }
        })
    },
    setSteps (flowName, flowId, step) {
      this.approvalFlowSteps.push({
        flowName: flowName,
        flowId: flowId,
        id: step.id,
        text: `${flowName} - ${step.name}`
      })
      if (step.children) {
        step.children.forEach((s) => {
          this.setSteps(flowName, flowId, s)
        })
      }
    },
    getApprovalFlow () {
      this.$store.dispatch('attemptGetApprovalFlow')
        .then(({ data }) => {
          if (data && data.approvalFlows.length) {
            data.approvalFlows.forEach((f) => {
              this.setSteps(f.name, f.id, f.step)
            })
          }
        })
    },
    getDependents () {
      this.$store.dispatch('attemptGetDependents')
        .then(({ data }) => {
          this.dependents = data.map(({ _id, businessName }) => ({ id: _id, text: businessName }))
          this.dependents.sort((a, b) => a.text.localeCompare(b.text))
        })
    }
  }
}
</script>
<template>
  <v-dialog :value="show" width="750" content-class="modal-invite-member--modal" persistent>
    <v-card class="modal-invite-member--container">
      <div class="modal-invite-member--header__wrapper">
        <h5 class="h7">{{ $t('modal.invite.member:title') }}</h5>
        <v-btn icon @click="close(true)" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="modal-invite-member--body mt-4">
        <input-field v-model="formData.email" outlined persistent-placeholder :validation="$v.formData.email" :label="$t('global:email')"></input-field>
        <div class="modal-invite-member--roles__wrapper">
          <h5 class="h8">{{ $t('modal.invite.member:roles.select') }}</h5>
          <v-radio-group v-model="selectedRole">
            <v-radio
              class="modal-invite-member--custom-role-option"
              v-for="(role, index) in availableRoles"
              :key="index"
              :label="$t(`modal.invite.member:roles.${role}`)"
              :value="role"
              color="#1200D3"
            >
              <template v-slot:label>
                <div class="modal-invite-member--custom-role-label">
                  <h5 class="h8">{{ $t(`modal.invite.member:roles.${role}`) }}</h5>
                  <p class="body--1">{{ $t(`modal.invite.member:roles.${role}.description`) }}</p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <select-field
            outlined
            multiple
            :items="approvalFlowSteps"
            v-model="formData.approvalFlow"
            item-value="id"
            :label="$t('modal.invite.member:approvalflow')"
          />
          <select-field
            outlined
            :items="dependents"
            v-model="formData.dependent"
            item-value="id"
            :label="$t('admin.center.bookmark:form.bookmark.title')"
          />
        </div>
        <div class="modal-invite-member--actions">
          <v-btn text color="#1200D3" @click="close" class="text-secondary mr-2 btn transform-unset">{{ $t('global:cancel') }}</v-btn>
          <v-btn class="btn transform-unset" dark color="#1200D3" @click="submit">{{ $t('global:confirm') }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-invite-member--modal {
  overflow: auto;
}
.modal-invite-member--container {
  padding: 17px 20px;
  text-align: left;
  .modal-invite-member--header__wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-invite-member--body {
    .modal-invite-member--description {
      margin: 12px 0 24px;
      color: $neutral-medium;
    }
    .modal-invite-member--custom-member {
      display: flex;
      align-items: center;
      .v-image {
        border-radius: 100px;
        margin-right: 10px;
      }
    }
    .modal-invite-member--custom-member-chip {
      display: flex;
      align-items: center;
      height: 32px;
      color: $neutral-darkest;
      background-color: $neutral-light;
      border-radius: 100px;
      padding-right: 16px;
      margin-right: 4px;
      .v-image {
        border-radius: 100px;
        margin-right: 10px;
      }
    }
  }
  .modal-invite-member--roles__wrapper {
    margin-top: 24px;
    .modal-invite-member--custom-role-option.v-radio {
      margin-bottom: 24px;
    }
    .modal-invite-member--custom-role-label {
      p.body--1 {
        margin: 0;
      }
    }
  }
  .modal-invite-member--actions {
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 768px) {
    .modal-invite-member--actions {
      .v-btn {
        width: calc(50% - 6px);
      }
    }
  }
}
</style>
